<template>
  <div class="d-flex mx-auto justify-center">
    <v-card width="500">
      <v-card-title style="font-size: 1.3em; color: gray"
        >遊戲歷程</v-card-title
      >
      <v-container class="d-flex justify-start flex-wrap">
        <v-card-text
        class="d-flex justify-center"
        style="font-size: 1.2em; color:#c7c7c7 ;font-weight: bold"
        v-if="historyList.length == 0">
        開始玩遊戲吧!
        </v-card-text>
        <v-card
          v-for="game in historyList"
          :key="game.id"
          class="d-flex justify-center ma-2 pa-0"
          rounded
          width="140"
        >
          <v-card-title style="font-size: 1em">
            {{ game.mode }}
          </v-card-title>
        </v-card>
      </v-container>
    </v-card>
  </div>
</template>


<script>
export default {
  name: "TimeLine",
  props: {
    historyList: { type: Array },
  },
};
</script>