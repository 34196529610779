<template>
  <v-container>
    <v-dialog v-model="openDialog" max-width="290" persistent>
      <v-card class="justify-center">
        <v-card-title class="grey lighten-4 py-2" style="font-size:1.1em"> 清除資料 </v-card-title>
        <v-card-text class="py-2 mb-2 justify-content: start" style="font-size:1em">資料不會被保存，不能反悔喔
        </v-card-text>
        <v-card-actions class="d-flex align-center pa-3">
          <v-spacer></v-spacer>
          <v-btn color="gray" text @click="clickBtn(false)"> 算了 </v-btn>
          <v-btn color="#EF5350" class="white--text" @click="clickBtn(true)">
            清除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "ClearData",
  data: () => ({
    openDialog : false,
  }),
  props:{
      dialogStat: Boolean,
  },
  methods:{
      clickBtn: function(value){
          console.log('Dialog Emit   >>  '+value);
          this.$emit("check",value);
      }
  },
  computed:{
  },
  watch:{
    dialogStat: function(){
      this.openDialog = this.dialogStat ;
    }
  }
};
</script>