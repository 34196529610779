<template>
  <v-app>
    <v-main>
      <v-container class="grey lighten-5 mx-auto" max-width="500">
        <v-row>
          <v-col>
            <counter
              @newGame="newGame"
              @reset="callDialog"
              @deleteGame="subGame"
              :totalPrice="totalPrice"
            >
            </counter>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <time-line :historyList="historyList"></time-line>
          </v-col>
        </v-row>
      </v-container>
      <clear-data :dialogStat="showDialog" @check="reset"></clear-data>
      <v-snackbar
        :timeout="2000"
        v-model="showSnackBar"
        absolute
        bottom
        color="#9CCC65"
        tile
        content-class="d-flex mx-auto pa-auto"
        height="10"
      >
        <v-container class="d-flex justify-center align-center">
          <v-icon>mdi-check-circle</v-icon>
          <p class="ma-0 pl-3">清除成功</p>
          </v-container>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import TimeLine from "./components/TimeLine.vue";
import Counter from "./components/Counter.vue";
import ClearData from "./components/ClearData.vue";

export default {
  name: "App",

  components: {
    Counter,
    TimeLine,
    ClearData,
  },

  data: () => ({
    gameHistory: [{ id: 0, mode: "" }],
    gameId: 0,
    historyList: [],
    showDialog: false,
    totalPrice: 0,
    gameMode: "",
    showSnackBar: false,
    showSnack: false,
  }),
  methods: {
    callDialog: function () {
      this.showDialog = true;
    },
    newGame: function (value) {
      let history = { id: this.gameId, mode: value.mode };
      this.historyList.push(history);
      this.gameId++;
      this.totalPrice += value.price;
    },
    subGame: function (value) {
      this.historyList.pop();
      this.totalPrice -= value.price;
    },
    reset: function (value) {
      console.log("reset >> " + value);
      if (value === true) {
        this.historyList = [];
        this.totalPrice = 0;
        this.gameMode = "";
        this.showSnackBar = true;
      }
      this.showDialog = false;
    },
  },
};
</script>
