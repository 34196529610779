<template>
  <v-container class="pa-0">
    <v-card class="mx-auto" width="500">
      <v-card-title class="pt-5 pl-4">飛鏢算錢小幫手</v-card-title>
      <v-card-subtitle class="pl-4">我窮，沒高級會員</v-card-subtitle>
      <v-container class="d-flex px-10 py-2 my-0">
        <v-select
          hide-details
          return-object
          :items="gameMode"
          :item-text="'mode'"
          v-model="nowMode"
          label="遊戲模式"
        />
      </v-container>
      <v-container class="d-flex justify-center align-center">
        <h3 class="mx-11">打鏢花了 {{ totalPrice }} 元</h3>
      </v-container>
      <v-container class="d-flex pb-10">
        <v-btn @click="addNumber()" class="mx-auto" width="300" large color="">打了一場 {{ nowMode.mode }}</v-btn>
      </v-container>
      <v-card-actions class="pb-10 my-0 d-flex justify-center align-center">
        <v-btn @click="subNumber()">上一動</v-btn>
        <v-btn @click="resetBtn(resetState)" color="grey lighten-4">清除全部</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Counter",

  components: {},

  data: () => ({
    gameMode: [
      { id: 1, mode: "301", price: 20 },
      { id: 2, mode: "501", price: 20 },
      { id: 3, mode: "701", price: 30 },
      { id: 4, mode: "Leg 3 match", price: 40 },
      { id: 5, mode: "3 Match加賽", price: 20 },
    ],
    nowMode: { id: 0, mode: "還沒選好", price: 0 },
  }),
  props: {
    totalPrice: { type: Number, default: 0 },
    resetState: { type: Boolean, default: false },
  },
  watch: {},
  methods: {
    addNumber: function () {
      if (this.nowMode.id != 0) {
        this.$emit("newGame", this.nowMode);
      }
    },
    subNumber: function () {
      if (this.nowMode.id != 0) {
        this.$emit("deleteGame", this.nowMode);
      }
    },
    resetBtn: function () {
      this.$emit("reset");
    },
  },
};
</script>
